.resetPassContainer {
    max-width: 440px;
    margin: 0 auto;
}

.backToResetPassword {
    display: block;
    margin-bottom: 22px;
}

.submit {
    width: 100%;
}

:global .disabled {
    cursor: not-allowed;
    color: var(--color-grey5);

    &:hover {
        color: var(--color-grey5);
    }
}
