.TransactionsCardWithBarChart {
    min-height: 352px;
    :global {
        .ant-card-body {
            padding: 5px 20px !important;
        }

        .ant-card-head-title {
            padding: 10px 0px;
        }
    }
}

.tabs {
    :global(.ant-tabs-nav-list) {
        min-width: 130px;
    }
}

.barChart {
    min-height: 296px;
}

.title {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: var(--font-size);

    &Mobile {
        justify-content: space-between;
    }

    &Text {
        margin-right: 10px;
    }
}

.mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header {
    :global(.ant-card-extra) {
        padding-bottom: 0;
    }

    &Mobile {
        :global(.ant-card-body) {
            padding-top: 0;
        }
    }

    &Compare {
        :global(.ant-card-head-title) {
            padding: 8px 0;
        }
    }

    &WithoutSettings {
        :global(.ant-card-extra) {
            padding-top: 6px;
        }
    }
}

.subheader {
    display: flex;
    justify-content: space-between;
}

.rightLabels {
    display: flex;
    align-items: center;
}

.previous {
    margin-right: 12px;
    font-size: 10px;
}

.current {
    font-size: 10px;
}

.month {
    margin-left: 65px;
}

.selectWrapper {
    height: 40px;
}

.rangePickers {
    margin-left: 10px;
}

.status {
    min-width: 180px;
    &Mobile {
        min-width: 10px;
    }
}

@media screen and (max-width: 922px) {
    .collapsed {
        :global {
            .ant-card-head-wrapper {
                display: block;
                padding: 0px;
                .ant-card-extra {
                    padding: 0px;
                }
            }
        }
    }
}
