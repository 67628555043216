.titleWrapper {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  &:hover {
    cursor: pointer;
  }
}
