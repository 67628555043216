.card {
    margin-bottom: 25px;
}

.headerTabs {
    :global {
        .ant-tabs-tab {
            height: 46px;
        }

        .ant-tabs-nav-list {
            align-items: flex-end;
        }

        .ant-tabs-extra-content {
            margin-left: auto;
        }
    }

    @media screen and (max-width: 1550px) {
        :global {
            .ant-tabs-nav {
                flex-direction: column-reverse;
                justify-content: space-between;
            }
        }
    }
}

.controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.exportButton {
    padding-left: 5px;
    gap: 2px;
}

.loading {
    :global {
        .ant-tabs-nav {
            .ant-tabs-nav-wrap {
                opacity: 0.4;
                pointer-events: none;
            }
        }
    }
}
