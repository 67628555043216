.datepickers{
  margin-top: 20px;

  &.isMobile{
    :global{
        .ant-picker{
            width: 50%;
        }
    }
  }
}

.buttons{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  & > button{
    width: 40% !important;
  }
}

.monthDatePickerPopup{
  :global{
    .ant-picker-header{
      display: none !important;
    }
  }
}

.yearDatePicker{
    border-radius: 12px 0 0 12px;
    border-right: none;
}

.monthDatePicker{
    border-radius: 0 12px 12px 0;
}

.monthDatePicker, .yearDatePicker{
  :global{
    .ant-picker-clear{
      display: none !important;
    }
  }
}