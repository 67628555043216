.backButton {
  padding: 0;
  display: flex;
  align-items: center;

  &:hover {
    background: transparent;
  }
}

.icon {
  margin-right: 8px;
}
