/**
COMMON STYLES
 */
.body {
    padding: 16px 24px;
}

/**
DESKTOP STYLES
 */

.headerExtra {
    display: flex;
    align-items: center;
}

.tabs {
    :global {
        .ant-tabs-nav {
            display: flex;
            flex-direction: column-reverse;
        }
        .ant-tabs-extra-content {
            align-self: flex-start;
        }
    }
}

.tabBarExtra {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    > *:not(:last-child) {
        margin: 0 var(--margin-sm) var(--margin-sm) 0;
    }

    &Mobile {
        flex-direction: column;
    }
}

.currency {
    &Mobile {
        max-width: 100px;
        margin-right: 0;
        margin-bottom: var(--margin-sm);
    }
}

.store {
    min-width: 200px;
    border: 1px solid var(--color-grey2);
    box-sizing: border-box;
    box-shadow: 0 6px 8px -4px rgba(85, 88, 92, 0.24);
    border-radius: var(--border-radius-sm);

    &Mobile {
        max-width: 100px;
        margin-right: 0;
        margin-bottom: var(--margin-sm);
        border: 1px solid var(--color-grey2);
        box-sizing: border-box;
        box-shadow: 0 6px 8px -4px rgba(85, 88, 92, 0.24);
        border-radius: var(--border-radius-sm);
    }
}
