.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--margin-xl);
}

.btn {
  margin-top: var(--margin-md);
}
