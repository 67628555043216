.button {
    width: 24px !important;
    height: 24px !important;
    padding: 0px;
    margin-right: 7px;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        background-color: transparent;
    }
    :global {
        .ant-btn-link:focus {
            background-color: transparent;
        }
        .ant-btn:focus {
            background-color: transparent;
        }
    }
}

.isLoading {
    color: var(--color-grey3);
    padding: 9px 20px;
}

.typeButton {
    padding: 9px 20px;
    border: 1px solid var(--color-grey2);
    box-shadow: 0 6px 8px -4px rgb(85 88 92 / 24%);
    color: var(--color-text);
    font-size: var(--font-size);
    border-radius: 8px;

    &:hover,
    &:focus {
        border: 1px solid var(--color-green2);
        background: white;
        color: var(--color-text);
    }
}

.icon {
    margin-right: 10px;
}

.typeButtonIcon {
    svg {
        path {
            fill: var(--color-grey7);
        }
    }
}
