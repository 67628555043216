.select {
    :global {
        .ant-select-arrow {
            right: 4px;
        }
    }

    :global {
        .ant-select {
            &:not(&-customize-input) .ant-select-selector {
                height: 40px;
                border: 1px solid var(--color-grey4);
            }

            &:not(&-disabled):hover .ant-select-selector {
                border-color: var(--color-green2);
            }

            &-multiple {
                &:not(&-customize-input) .ant-select-selector {
                    height: 100%;
                    border: 1px solid var(--color-grey4);
                }
            }

            &-item-option {
                &-selected:not(&-disabled) {
                    background-color: var(--color-green1) !important;
                }
            }
        }
    }
}

.dropdown {
    border-radius: 12px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    :global {
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: var(--color-green1) !important;
        }

        .ant-select-item {
            border-radius: 0 !important;
        }
    }
}
