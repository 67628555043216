@use '~/src/assets/styles/vars' as *;

.loading {
    input {
        @include placeholder();
    }
}

.loadingIcon {
    color: var(--color-green2);
}
