.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panelHeader {
    :global {
        .ant-collapse-header.ant-collapse-header {
            padding-left: 0;
        }

        .ant-collapse-expand-icon {
            margin-top: 19px;
        }
    }
}
