.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 550px;
  width: 100%;
  display: block;
  font-size: 16px;
}

.descriptionLabel {
  margin-bottom: 24px;

  :global {
    .ant-descriptions-item-label {
      min-width: 264px;
      font-size: 16px;
    }

    .ant-descriptions-item-content {
      font-size: 16px;
    }

    .ant-descriptions-item {
      padding-bottom: 6px;
    }

    .ant-descriptions-row {
      padding-bottom: 10px;
    }
  }
}

.descriptionLabelMobile {
  :global {
    .ant-descriptions-item-label {
      color: var(--color-grey4);
    }

    .ant-descriptions-item {
      padding-bottom: 10px;
    }

    .ant-descriptions-row {
      padding-bottom: 6px;
    }
  }
}
