@use '~/src/assets/styles/vars' as *;

.paginationContainer {
    margin: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pagination {
    display: flex;
    align-items: center;

    :global {
        .ant-typography-disabled {
            @include icon-svg-fill(var(--color-grey3));

            &:hover {
                color: var(--color-grey3);
                @include icon-svg-fill-hover(var(--color-grey3));
            }
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-text);
    transition: all 0.3s;
    line-height: 40px !important;

    @include icon-svg-fill(var(--color-text));

    &:hover {
        color: var(--color-secondary);
        @include icon-svg-fill-hover(var(--color-secondary));
    }
}
