.modal {
    :global {
        .ant-modal-footer {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ant-tabs-nav {
            margin-top: 0 !important;
            margin-bottom: 16px;
        }
    }
}

.close {
    margin-right: 16px;
}

.submit,
.close {
    margin-bottom: 0;
}
