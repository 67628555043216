.listItem {
  font-size: calc(var(--font-size) + 2px);
  transition: all .3s;
  span {
    transition: opacity .2s ease-in-out;
    opacity: 0;
    padding-right: var(--padding-xs);
  }

  &:hover {
    background: var(--color-grey1);
    transition: all .3s;
    span {
      opacity: 1;
      transition: opacity .2s ease-in-out;
      cursor: pointer;
    }
  }
}
