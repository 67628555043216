.panel {
  :global(.ant-collapse-content-box) {
    background: var(--color-grey1);
    padding-left: 22px;
    margin: 0 -16px 0 -16px;
  }
}

.formItem {
  margin-bottom: 0;
}

.select, .input, .multiselect {
  max-width: 250px;
 
}

.select, .input{
    :global(.ant-select-selector) {
        box-shadow: 0 6px 8px -4px rgba(85, 88, 92, 0.24) !important;
    }
}
