.select {
    width: 100%;
    margin-top: 40px;

    &.mobile {
        margin-top: 0;
    }
}

.buttonsHolder {
    text-align: right;
    margin-top: 10px;
}
