.posCard {
  max-width: calc(50% - 8px);

  &Mobile {
    max-width: 100%;
  }

  &Laptop {
    max-width: 80%;
  }
}
