.emptyBlock {
  height: 80px;
}

.alert {
  &Image {
    max-width: 120px;
  }

  &ImageMobile {
    margin-left: -24px;
  }
}
