.modal {
    margin-left: auto;
    margin-right: auto;
    max-width: 62.5rem;
    overflow: hidden;
    position: absolute;
    bottom: 1rem;
    top: auto;
    right: 0;
    left: 0;
    width: 100vw !important;
    padding-left: 1rem;
    padding-right: 1rem;

    :global {
        .ant-modal-body {
            padding: 50px !important;
        }
    }
}

.collapse {
    :global {
        .ant-collapse-item {
            .ant-collapse-header {
                padding-left: 0;
            }
        }
    }
}

.links {
    margin-top: var(--margin-lg);
}
