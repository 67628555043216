.container {
    max-width: 800px;
    padding: 0 !important;
}

.divider {
    margin-top: 0;
}

.descriptionLabel {
    :global {
        .ant-descriptions-row > td {
            padding-bottom: 24px;
        }

        .ant-descriptions-item-label {
            min-width: 264px;
            color: rgba(0, 0, 0, 0.85);
        }
    }

    table {
        tr {
            &:last-child {
                td {
                    padding-bottom: 16px !important;
                }
            }
        }
    }
}

.descriptionLabelMobile {
    :global {
        .ant-descriptions-item-label {
            color: var(--color-grey4);
        }

        .ant-descriptions-item {
            padding-bottom: 10px;
        }

        .ant-descriptions-row {
            padding-bottom: 6px;
        }
    }
}

.customerId.customerId {
    margin-bottom: 0;
}
