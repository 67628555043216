@use '~/src/assets/styles/vars' as *;

.root {
    :global(.ant-card-extra) {
        padding-bottom: 0;
    }

    :global(.ant-card-head) {
        height: 62px;
    }

    &.mobile {
        :global(.ant-card-body) {
            padding-top: 1em;
        }
        :global(.ant-card-head) {
            height: 52px;
        }
    }
}

.title {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.text {
    font-weight: 400;
    font-size: var(--font-size-small);

    :global {
        .ant-typography {
            font-size: 24px;
        }
    }

    @include for-desktop-up {
        font-size: var(--font-size-subtitle);
    }

    @include for-laptop-up {
        font-size: var(--font-size);
    }
}

.divider {
    height: 7em;
    margin: 0 2em;
}
