.problems {
  display: flex;
  flex-direction: column;

  :global(.ant-radio-wrapper) {
    margin-bottom: var(--margin-lg);
  }

  &Title {
    margin-bottom: var(--margin-xl);
  }
}
