.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  margin-bottom: 16px;
}

.flex {
  display: flex;
}

.title {
  color: var(--color-grey4);
}

.input {
  margin-right: 16px;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;

  :global(.ant-divider) {
    height: 2em;
  }
}

.email {
  margin-left: 4px;
  margin-right: 19px;
}

.sms {
  margin-left: 7px;
}

.copy {
  margin-left: 20px;
}
