.root {
    max-width: 100% !important;
    display: inline-flex;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 2px;
    font-size: 14px;
}

.clickable {
    &:hover {
        cursor: pointer;
    }
}
