
.amountWrapper {
    margin: 15px auto 24px;
    text-align: center;
    line-height: 1;
}

.currency {
    font-size: 30px;
    margin-right: 4px;
}

.amount {
    color: #000;
    font-size: 40px;
    letter-spacing: -2px;
}

.infoText {
    margin: 0 0 30px;
    font-size: 14px !important; 
}

.block {
    margin-bottom: 12px;
}


.largeBlock {
    padding: 25px 20px !important;
}

.buttons {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
}

.continueButton {
    border: 1px solid var(--primary-color);
}

.cancel {
    margin-right: 15px;
}

.continueButton,
.cancel {
    padding: 8px 0;
    width: 100%;
}

.continueText {
    margin: 0 0 10px;
}

p.smalltext {
    font-size: 12px;
    margin: 15px auto 0;
    max-width: 320px;
}

.footertext {
    font-size: 12px;
    margin: 24px 0 0 0;
}
