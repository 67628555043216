.filterContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 12px;

    :global {
        .ant-select {
            width: 190px;
        }
        .ant-btn > .ant-btn-icon + span {
            margin-left: 0 !important;
        }
    }
}

.selectDropdown {
    min-width: 234px !important;
}

:global(.ant-table-thead > tr > th:first-child) {
    padding-left: 24px;
}

:global(.ant-table-tbody > tr > td:first-child) {
    padding-left: 24px;
}
