.body {
  display: flex;
  margin-top: 8px;
  margin-bottom: 36px;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  background-image: url("/src/assets/img/activation/initialCheckModalAvatar.png");
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 20px;
}

.text {
  background-color: var(--color-grey8);
  padding: 12px 16px;
  border-radius: var(--border-radius-md);
}
