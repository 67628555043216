.formSubtitle {
  color: var(--color-grey4);
  :global {
    .ant-form-item-label {
      padding: 0;
      label {
        color: var(--color-grey4);
      }
    }
  }
}

.divider {
  margin: var(--margin-md) 0;
}
