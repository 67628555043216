@use '~/src/assets/styles/vars' as *;

.actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: var(--margin-sm);

    &.hasLink {
        min-width: 160px;
    }
}

.ellipsis {
    @include hover-cursor-pointer;
}
