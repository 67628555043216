.selectWrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    background: #ffffff;
    border: 1px solid var(--color-grey2);
    box-sizing: border-box;
    box-shadow: 0 6px 8px -4px rgb(85 88 92 / 24%);
    border-radius: var(--border-radius-sm);
    height: 40px;
    min-width: 180px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 8px;

    &Mobile {
        min-width: unset;
    }

    &:hover {
        border: 1px solid var(--color-green2);
    }

    :global {
        .ant-select-selector {
            border: none !important;
            padding-right: 8px !important;
            padding-left: 10px !important;
        }

        .ant-divider-vertical {
            margin-left: 8px;
            margin-right: 8px;
        }
    }
}

.suffix {
    display: flex;
    justify-content: flex-end;
}

.dropdown {
    min-width: 210px !important;
}

.dropdownMobile {
    min-width: 150px !important;
}

.text {
    font-size: var(--font-size);
    color: var(--color-secondary);
    margin: 0 10px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.extra {
    display: flex;
    align-items: center;
    justify-content: center;
}

.select {
    :global {
        .ant-select-arrow {
            right: -8px;
        }

        .ant-select-selector.ant-select-selector.ant-select-selector.ant-select-selector {
            height: 38px;
            box-shadow: none;
            background: transparent;
        }
    }
}

.divider {
    margin-left: 0;
}
