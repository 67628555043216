.collapse {
    :global {
        .ant-collapse-item {
            .ant-collapse-header {
                padding-left: 0;
            }
        }
    }
}

.links {
    margin-top: var(--margin-lg);
}
