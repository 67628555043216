.modal {
  :global {
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.close {
  margin-right: 16px;
}

.submit, .close {
  margin-bottom: 0;
}

.sectionPermissionsDivider {
  margin-top: 5px;
}

.radioGroup {
  width: 100% !important;
  text-align: center;
}
