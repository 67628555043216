@use '~/src/assets/styles/vars' as *;

.root {
    display: flex;
    align-items: center;
    padding: 16px;
    position: relative;
    z-index: 1;
    background-color: var(--color-primary);
    box-shadow: 0px 0px 12px 0px var(--color-box-shadow);

    :global(.ant-list-item-meta-description) {
        font-size: 12px;
        color: var(--color-text);
    }

    :global(.ant-list-item-meta-title) {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
    }
}

.closeButton {
    pointer-events: 'none';
    margin-right: 1rem;
}

.getButton {
    border-radius: 41px !important;
    border: none;
    box-shadow: 0px 3px 16px -4px rgba(235, 191, 20, 0.41);
    box-shadow: 0px 1px 18px -2px rgba(56, 56, 55, 0.28);

    &,
    &:hover,
    &:visited {
        color: var(--color-text) !important;
        background-color: var(--color-white) !important;
    }
}
