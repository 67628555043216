@use '~/src/assets/styles/vars' as *;

.MultiSelectItem {
    padding: 5px 12px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    &:hover {
        background-color: #f5f5f5;
    }
    &:global(.selected) {
        background-color: #e6f7ff;
        color: var(--color-secondary);
    }
}

.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;       
}
