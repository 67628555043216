@media screen and (min-width: 767.9px) {
    .ecospendModal {
        width: 600px !important;
    }
}

.ecospendModalHeader {
    padding: 15px 32px;
}

.ecospendModalContent {
    padding: 15px 36px 52px;
}