.title {
    text-align: center;
}

.image {
    max-width: 237px;
    max-height: 237px;
}

.description {
    text-align: center;
}

.modal {
    :global {
        .ant-modal-body {
            padding: 50px !important;
        }
    }
}
