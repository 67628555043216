.root {
    :global {
        .ant-popover-title {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 400;
            font-size: 16px;
            padding: 12px 16px;
        }

        .ant-form-item {
            width: 100%;
            margin: 0;
        }

        .ant-divider {
            margin: 16px -16px;
            width: calc(100% + 32px);
        }

        .ant-space {
            width: 100%;
            justify-content: flex-end;
        }
    }
}
