.selectContainer {
    display: flex;
    align-items: center;
    margin: var(--margin-sm) var(--margin-lg) 0 0;
    padding-left: var(--padding-lg);
    padding-right: var(--padding-lg);
    border-radius: 0 8px 8px 0;
    transition:
        border-color 0.3s,
        background 0.3s,
        padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover {
        background-color: var(--color-green1) !important;
    }

    &.disabled {
        :global {
            .ant-select-selector {
                cursor: default !important;
            }

            .ant-select-arrow {
                display: none !important;
            }
        }

        &:hover {
            background-color: var(--color-white) !important;
        }
    }

    &.collapsed {
        position: relative;
        justify-content: center;
        margin-right: 0;
        border-radius: 0;
        padding: 10px 0;
        cursor: pointer;

        .select {
            position: absolute;
            left: 0;
            top: 0;
            min-width: auto;
            opacity: 0;
            height: 100% !important;
            width: 100% !important;
        }

        :global {
            .ant-select-selector {
                height: auto !important;
            }
        }
    }

    :global {
        .ant-select-selection-item {
            display: flex;
            align-items: center;
            line-height: normal !important;

            :local {
                .option {
                    margin: 0 !important;
                }
                .optionLabel,
                .optionSubLabel {
                    max-width: 200px;
                }
            }
        }

        .ant-select-selector {
            height: 55px !important;
        }
    }
}

.select {
    height: auto;
    min-width: 235px;

    span {
        color: var(--color-text) !important;
        font-size: var(--font-size-subtitle);
        max-width: 210px !important;
    }

    :global(.ant-select-selector) {
        border: none !important;
        background-color: transparent !important;
    }
}

.dropdown {
    margin-top: var(--margin-lg);
    left: 24px !important;
    min-width: 255px !important;
    max-width: 255px !important;
}

.option {
    &Label,
    &SubLabel {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &Label {
        color: var(--color-text);
        margin-right: var(--margin-sm);
    }

    &SubLabel {
        font-size: var(--font-size-small);
        color: var(--color-grey4);
        font-weight: normal;
    }
}
