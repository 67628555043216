@use '~/src/assets/styles/vars' as *;

@mixin divider($color) {
  border-right: 1px solid $color;
  padding-right: 14px;
  height: 100%;
  display: flex;
  align-items: center;
}

/**
  COMMON STYLES
 */

.rangePicker {
    border-radius: 0 12px 12px 0;
    border-left: none;

    :global {
        .ant-picker-input > input {
            max-width: 100px;
        }
    }
}

.group.group {
  display: flex;
  width: auto;
  transition: opacity 0.5s ease; 
  column-gap: 0;
}

.antPickerRange {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid var(--color-grey2);
  box-sizing: border-box;
  box-shadow: 0 6px 8px -4px rgb(85 88 92 / 24%);
  border-radius: var(--border-radius-sm);
  height: 40px;
  min-width: 265px;
  max-width: 330px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    cursor: pointer;
  }

  :global {
    .ant-picker {
      height: 38px;
    }
  }

  &:hover {
    border: 1px solid var(--color-green2);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    :global {
      .ant-typography {
        @include divider(var(--color-green2));
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  &_title {
    margin: 0 0 0 16px;
    @include divider(var(--color-grey3));
  }

  :global(.ant-picker) {
    border: none;
    padding: 0 10px 0 0;
    max-width: 150px;
    box-shadow: none;
  }

  :global {
    .ant-picker {
      &-range-separator {
        padding-left: 0;
      }

      &-disabled {
        background: none;
      }
    }

    .ant-picker-disabled {
      background: none;
    }

    .ant-divider {
      height: 39px;
    }

    .ant-picker-active-bar {
      background: var(--color-secondary);
      margin-left: 0;
    }
  }

  &WithoutPanel {
    min-width: 160px;
    max-width: 170px;
  }

  &Disabled {
    background-color: var(--color-grey9);
    border-right-width: 1px;
    cursor: not-allowed;

    :global(.ant-typography) {
      color: var(--color-grey3);
      @include divider(var(--color-grey3));
    }

    &:hover {
      border: none;
      :global {
        .ant-typography {
          @include divider(var(--color-grey3));
        }
      }
    }
  }
}

.select {
  min-width: 140px;
  height: 40px;
  
  &Mobile {
    min-width: 150px;
  }

  :global {
    .ant-select-selector{
        border-radius: 12px 0 0 12px;
    }

    .ant-select-selection-item{
        text-align: center;
    }
  }
}

.calendarIcon {
  font-size: var(--font-size-subtitle);
  margin: 0 8px;

  &Disabled {
    svg {
      path {
        fill: var(--color-grey3);
      }
    }
  }
}

.divider {
  margin: 0;
  top: 0;
}

/**
  MOBILE STYLES
 */
.mobileFooter {
  &_title {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: var(--font-size);
    line-height: var(--line-height);
  }
  &_button {
    width: 100%;
  }
}

.mobileHeader {
  padding-bottom: 0;
}

.antMobilePickerDropdown {
  position: fixed;
  padding: 0;
  top: 0 !important;
  left: 0 !important;
  :global {
    .ant-picker {
      &-panels  {
        width: 100%;
        display: flex;
        flex-direction: column;

        & > *:first-child button.ant-picker-header-next-btn {
          visibility: visible !important;
        }
        & > *:first-child button.ant-picker-header-super-next-btn {
          visibility: visible !important;
        }

        & > *:last-child button.ant-picker-header-prev-btn {
          visibility: visible !important;
        }
        & > *:last-child button.ant-picker-header-super-prev-btn {
          visibility: visible !important;
        }

        & > *:first-child {
          align-items: center;
          width: 100%;
        }

        & > *:last-child {
          align-items: center;
          width: 100%;
        }
      }

      &-panel {
        &-container {
          height: 100vh;
          overflow-y: scroll;
        }
      }

      &-footer {
        width: 100vw;
        margin-bottom: 10px;

        &-extra {
          & > div {
            flex-wrap: wrap !important;
          }
        }
      }
    }
  }
}

/**
  DESKTOP STYLES
 */

.header {
  padding: 0 0 10px;
  :global {
    .ant-page-header {
      &-healing {
        &-title {
          color: var(--color-grey4);
        }
      }
    }

    .ant-page-header-healing-title {
      color: var(--color-grey4);
    }
  }
}

.antPickerDropdown {
  :global {
    .ant-picker-panel-container {
      padding: 16px;
      border-radius: 12px;
    }
  }
}

.antPickerDropdownContainer {
  display: flex;
}

.periodList {
  border-right: 1px solid var(--color-grey6);
  margin: auto;
  padding-right: 16px;

  :global {
    .ant-list {
      &-item {
        border: none;
      }
    }
  }
}

.dropdownPanel {
  display: flex;
  flex-direction: row-reverse;

  &Mobile {
    :global {
      .ant-picker-ranges {
        display: none;
      }
    }
  }

  :global {
    .ant-picker-footer {
      min-width: 8rem;
    }

    .ant-picker-ranges {
      padding: 8px;
      margin-left: -8px;
    }

    .ant-picker-preset {
      height: 2rem;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
      display: inline-flex;
      font-size: 14px;
      width: 100%;
      height: 100%;
      align-items: center;
      border: none;
      background: none;
      color: var(--color-grey5);
    }

    .ant-picker-ranges .ant-picker-preset > .ant-tag-blue:hover {
      color: var(--color-text);
      background-color: var(--color-green1);
    }

    .ant-picker-cell-inner::after {
      left: 0 !important;
      right: 0 !important;
    }
  }
}

.loading{
    opacity: .4;
    pointer-events: none;
}