.root {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.container {
    display: flex;
}

.select {
    margin-right: var(--margin-sm);
}

@media screen and (max-width: 1035px) {
    .container {
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-right: 0;
        max-width: 530px;
    }

    .divider {
        display: block;
        width: 100%;
        margin-top: var(--margin-sm);
    }
}
