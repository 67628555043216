.loadingIcon{
    font-size: 48px;
    color: var(--color-grey7);
}

.spinHolder{
    display: flex;
    justify-content: center;

    margin-bottom: 25px;
}

.title{
    font-size: 18px !important;
}

.text{
    color: var(--color-grey9);
}

.title, .text{
    display: block;
    text-align: center;
} 
