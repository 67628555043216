.MultiSelectContent {
    padding: 12px 0;
    :global(.ant-checkbox-wrapper) {
        margin-right: 8px;
    }
    :global(.ant-divider-horizontal) {
        margin: 5px 12px;
        width: auto;
        min-width: auto;
    }
}

.selectItem {
    padding: 5px 12px;
}

.noData {
    @extend .selectItem;
    text-align: center;
}