.buttons{
    margin-top: 20px;
}

.steps{
    margin-bottom: 20px;
}

.buttonsHolder{
    display: flex;
    justify-content: flex-end;
}