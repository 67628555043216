.body {
    min-height: 500px;
}

.backBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 160px;
    padding: 0;
    margin-top: -20px;
    margin-bottom: 20px;

    :global {
        .ant-btn-icon {
            display: inline-flex;
        }
    }
}
