.card {
  width: 100%;
}

.copy {
  &:hover {
    cursor: pointer;
  }
}

.divider {
  height: 62px;
}

.cardNumber {
  margin-bottom: var(--margin-md);
  height: 25px;
}

.freezeCardColumn, .blockCardColumn {
  display: flex;
  align-items: center;
}

.freezeCardLink {
  font-size: calc(var(--font-size) + 2px);
}

.getCVV {
  margin-right: var(--margin-sm);
}
