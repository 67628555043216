@use '~/src/assets/styles/vars' as *;

/**
MOBILE STYLES
 */
.mobileLink {
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--color-grey4);
  background: #FFFFFF;
  border: 1px solid var(--color-grey2);
  box-sizing: border-box;
  box-shadow: 0 6px 8px -4px rgba(85, 88, 92, 0.24);
  border-radius: var(--border-radius-sm);

  @include icon-svg-fill-hover(var(--color-green2));
}

/**
DESKTOP STYLES
 */
.link {
  display: flex;
  align-items: center;
  transition: 0.3s;

  @include icon-svg-fill-hover(var(--color-green2));
}
