.payPalButtonImg {
  width: 15px;
  margin-right: 10px;
}

.checkbox {
  margin-bottom: 20px;
}

.payPalButton.payPalButton {
  display: inline-flex;
  align-items: center;
  padding-top: 10px !important;

  &Disabled {
    opacity: 0.5;
    pointer-events: none;
    background: var(--color-grey3);
  }
}
