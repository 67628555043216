.header {
    :global {
        .ant-page-header-heading {
            margin-bottom: 0;
        }
    }
}

.cardHeader.cardHeader {
    font-size: 18px;
}

.headerTitle.headerTitle {
    margin-bottom: 0;
}

.divider {
    margin: 0 24px;
    width: auto;
    min-width: auto;

    &Mobile {
        margin: 0 16px;
    }
}

.card {
    box-shadow: 0px -1px 0px 0px rgba(237, 238, 242, 1) inset;
    box-shadow: 0px 4px 16px 0px rgba(85, 88, 92, 0.08);

    &Description {
        color: var(--color-secondary);
        font-size: 16px;
        font-weight: 400;
        max-width: 100%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    :global {
        .ant-typography {
            margin-bottom: 0 !important;
        }
    }
}
