.input {
    :global(.ant-input-suffix) {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: rgba(0, 0, 0, 0.25);
        font-size: 12px;
    }

    &__disabled {
        svg {
            cursor: not-allowed;
        }
    }
}

.popover {
    :global(.ant-popover-inner-content) {
        padding: 0 !important;
    }
}
