@use '~/src/assets/styles/vars' as *;

.drawer {
  :global {
    .ant-drawer-body {
      padding-top: 0;
    }
  }
}

.expandIcon {
  @include icon-svg-fill(var(--color-grey4));
}

.accordion {
  :global {
    .ant-collapse-item > .ant-collapse-header, .ant-collapse-content-box {
      padding-left: 0 !important;
    }

    .ant-collapse-header {
      border-bottom: 1px solid var(--color-grey6);
    }
  }
}

.panel {
  font-size: var(--font-size-subtitle);
  color: var(--color-text);
}

.description {
  border-bottom: 1px solid var(--color-grey6);
  padding: 14px 0;

  &:first-child {
    padding-top: 0;
  }
}
