.divider {
  margin-bottom: 4px;
  margin-top: 20px;
}

.title.title {
  margin-bottom: 0;
  text-wrap: initial;
}

.header {
    padding-bottom: 0 !important;
  padding-top: 30px;
  max-width: 100vw;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
