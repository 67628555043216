.container {
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid var(--color-grey3);
  box-shadow: 0 6px 8px -4px rgba(85, 88, 92, 0.24);
}

.dropdown {
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}

.dropdownOverlay {
  max-width: 750px;
  max-height: 400px;
  overflow-y: scroll;
  box-sizing: border-box;
  border: 1px solid var(--color-grey3);
  box-shadow: 0 6px 8px -4px rgba(85, 88, 92, 0.24);
  border-radius: 12px;
}

.displayNone {
  display: none;
}

.selectButton {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: var(--color-grey4);
}

.selectedCard {
  padding: 0 20px 20px 20px;
}

.alert.alert {
  margin: 20px;
  max-width: calc(100% - 40px);
}
