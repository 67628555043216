$button-size: 60px;
$button-spacing: 40px;
$icon-size: 24px;

.button {
    position: fixed;
    bottom: $button-spacing;
    right: $button-spacing;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $button-size !important;
    width: $button-size !important;
    border-radius: 50% !important;

    &Mobile {
        bottom: calc($button-spacing/2);
        right: calc($button-spacing/2);
    }

    span {
        font-size: $icon-size;
    }
}
