.hideIcon {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    fill: var(--sendbird-light-primary-500) !important;
  }
}
