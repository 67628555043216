@use '~/src/assets/styles/vars' as *;

.alert {
  border: 1px solid var(--color-grey6);
  border-radius: var(--border-radius-sm);
  padding: 16px 15px;
  margin: 0 0 20px;
  width: 100%;

  &Warning {
    border: 1px solid var(--color-yellow1);
  }

  &Header {
    display: flex;
    align-items: flex-start;
  }

  &Content {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    &Mobile {
      flex-direction: column;
      align-items: center;
    }
  }

  &Details {
    display: flex;
    flex-direction: column;
  }

  &Title {
    font-weight: 600;
    font-size: var(--font-size);
    flex-grow: 1;
  }

  &Icon {
    margin: 0 var(--margin-sm) 0 0;

    &Warning {
      @include icon-svg-fill(var(--color-primary-darker));
    }
  }

  &Text {
    font-weight: 400;
    font-size: var(--font-size);
  }
}

.mobile {
  &.alert {
    padding: var(--padding-md);
    margin: 0;
  }
}
