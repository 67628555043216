.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    border: 1px solid var(--color-grey2);
    box-shadow: var(--base-shadow-1);
    color: var(--color-text);
    font-size: var(--font-size);
    border-radius: var(--border-radius-sm) !important;
    margin-right: var(--margin-sm);
    background-color: white;

    &:hover,
    &:active,
    &:focus {
        border: 1px solid var(--color-green2);
        background: white;
        color: var(--color-text);
    }
}
