.headerTabs {
    :global {
        .ant-tabs-tab {
            height: 46px;
        }
        .ant-tabs-nav-list {
            align-items: flex-end;
        }
        .ant-tabs-extra-content {
            margin-left: auto;
        }
    }

    @media screen and (max-width: 1250px) {
        :global {
            .ant-tabs-nav {
                flex-direction: column-reverse;
                justify-content: space-between;
            }
        }
    }
}

.tableRow {
  :global {
    .ant-table-row {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
