.titleBlock {
  margin-bottom: var(--margin-md);
}

.title.title {
  margin-bottom: 0;
}

.steps {
  line-height: 1.7;
}

.body {
  padding: var(--padding-lg);
}

.divider {
  margin: var(--margin-md) 0;
}

.editLink {
  margin-bottom: var(--margin-lg);
}
