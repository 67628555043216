.labelSecondary {
    font-size: 12px;
    color: var(--color-grey4);
}

:global(.ant-select-selection-item) {
    .labelSecondary {
        display: none;
    }
}
