.rangePickers {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &Tablet {
    justify-content: start;
    margin-left: 10px;
  }

  &Mobile {
    flex-direction: column;
    align-items: baseline;
  }

  .dash {
    padding: 5px;
  }
}

.rangePicker {
  &Mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
  }
}
