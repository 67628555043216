.avatar {
    height: 46px;
    width: 46px;
}

.descriptionLabel {
    :global {
        .ant-descriptions-item-label {
            color: var(--color-grey4) !important;
            min-width: 264px;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: -4px;
        }

        .ant-descriptions-item-content {
            font-size: 12px;
            font-weight: 400;
        }

        .ant-descriptions-item {
            padding-bottom: 6px !important;
        }

        .ant-descriptions-row {
            padding-bottom: 10px;
        }
    }
}

.card {
    box-shadow: 0 4px 16px 0 rgba(85, 88, 92, 0.08);
    margin-bottom: 16px;
    max-width: 800px;

    :global {
        .ant-card-body {
            display: flex;
        }
    }
}

.cardMobile {
    :global {
        .ant-card-body {
            display: block;
        }
    }
}

.rightIcon {
    position: absolute;
    right: 20px;
    top: 24px;
    svg {
        fill: var(--color-grey4);
    }
}

.map.map {
    height: 180px;
    min-width: 180px;
    max-width: 180px;
    border-radius: 16px;
}

.mapMobile.mapMobile {
    min-width: 100%;
    max-width: 100%;
}
