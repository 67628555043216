@use '~/src/assets/styles/vars' as *;

.tag {
    border-radius: 8px !important;
    padding: 2px 8px 2px 6px;
    max-width: 300px;
}

.default {
    color: var(--color-grey5);
    background-color: var(--color-grey2);
    @include icon-svg-fill(var(--color-grey5));
}

.processing {
    color: var(--color-blue2);
    background-color: var(--color-blue1);
    @include icon-svg-fill(var(--color-blue2));
}

.active {
    color: var(--color-secondary);
    @include icon-svg-fill(var(--color-secondary));

    & {
        padding: 0;
        background: none !important;
    }
}

.success {
    color: var(--color-secondary);
    background-color: var(--color-green4);
    @include icon-svg-fill(var(--color-secondary));
}

.warn {
    color: var(--color-yellow1);
    @include icon-svg-fill(var(--color-yellow1));

    & {
        background-color: var(--color-yellow2);
    }
}

.error {
    color: var(--color-red1);
    @include icon-svg-fill(var(--color-red1));

    & {
        background: none !important;
        padding: 0;
    }
}

.disabled {
    color: var(--color-grey4);
    @include icon-svg-fill(var(--color-grey4));

    & {
        justify-content: start;
        padding: 0;
        background: none !important;
    }
}
