.body {
  padding: 0 calc(var(--padding-sm) + var(--padding-md));
}

.creditCard {
  min-width: 248px;
  margin-right: var(--margin-md);
  margin-bottom: var(--margin-md);
}

.management {
  display: flex;
  justify-content: space-between;

  &Mobile {
    justify-content: start;
    flex-direction: column;
  }
}
