@use '~/src/assets/styles/vars' as *;

.expandIcon {
    @include icon-svg-fill(var(--color-grey4));
}

.accordion {
    :global {
        .ant-collapse-item > .ant-collapse-header,
        .ant-collapse-content-box {
            padding-left: 0 !important;
        }

        .ant-collapse-header {
            border-bottom: 1px solid var(--color-grey6);
        }
    }
}

.panel {
    font-size: var(--font-size-subtitle);
    color: var(--color-text);
}

.descriptionMobile {
    border-bottom: 1px solid var(--color-grey6);
    padding: 0;

    &:first-child {
        padding-top: 0;
    }
}

.timeline {
    margin-top: 15px;
}

.timelineItem {
    :global {
        .ant-timeline-item {
            &-label {
                position: absolute;
                left: 0;
                width: calc(50% - 72px) !important;
                inset-block-start: -5px !important;
                text-align: right;
            }

            &-content {
                top: -5px;
                inset-inline-start: calc(50% - 20px) !important;
                margin-inline-start: 0 !important;
            }

            &-tail {
                left: calc(50% - 45px) !important;
                display: block !important;
            }

            &-head {
                width: 8px;
                height: 8px;
                left: calc(50% - 48px);
                background: var(--color-green2);
                margin-inline-start: 0 !important;

                inset-inline-start: calc(50% - 48px) !important;

                &-blue {
                    color: var(--color-green2);
                    border-color: var(--color-green2);
                }
            }
        }
    }

    &Row {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &Title {
        color: var(--color-grey7);
    }
}

.iconTitle {
    font-size: var(--font-size-subtitle);
}

.statusTagHolder {
    margin-top: 5px;

    :global {
        .ant-divider {
            display: none;
        }
    }
}
