.loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: white;
}

.listItem {
  background: white;
  padding: var(--padding-lg);
}

.avatar {
  margin-right: calc(var(--margin-lg) - 4px);
}

.amount {
  margin-right: 86px;
}
