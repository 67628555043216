.headerMobile {
  :global(.ant-card-body) {
    padding-top: 0;
  }
}

.row {
  :global(.recharts-wrapper) {
    margin: auto;
  }
}

.tabs {
  margin-top: -20px;
  max-width: 130px;
}

.mobileTabs {
  margin-top: var(--margin-sm);
}
