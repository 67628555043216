.root {
    position: relative;
}

.loader {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 24px;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
}

.tableRow {
    :global {
        .ant-table-row {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
