.button {
    min-width: 100px;
    height: 40px !important;
}

.devices {
    margin-top: 30px;
}

.title {
    margin-right: 5px;
}
