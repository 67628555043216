.layout {
    min-height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 100%;
    margin: 0px auto 40px;
}

.logo {
    display: flex;
    justify-content: center;
    margin: 40px auto;
    img {
        width: 250px;
    }
}

.language{
    position: absolute;
    right: var(--margin-lg);
    top: var(--margin-lg);
}
