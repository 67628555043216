@use '~/src/assets/styles/vars' as *;

.icon {
    font-size: 24px;
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.3em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hoverable {
    @include icon-svg-fill-hover(var(--color-green2));
    &:hover {
        cursor: pointer;
    }
}
