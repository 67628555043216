@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/OpenSans-Regular.woff') format('woff'),
    url('../fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Regular'), local('Poppins-Regular'),
    url('../fonts/Poppins-Regular.woff2') format('woff2'),
    url('../fonts/Poppins-Regular.woff') format('woff'),
    url('../fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal; 
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUI';
    src: local('SegoeUI Regular'), local('SegoeUI-Regular'),
    url('../fonts/SegoeUI-Regular.woff2') format('woff2'),
    url('../fonts/SegoeUI-Regular.woff') format('woff'),
    url('../fonts/SegoeUI-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} 