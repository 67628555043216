.pagination {
  display: flex;
  align-items: center;

  &Mobile {
    justify-content: end;
  }

  &Label {
    margin-right: 8px;
  }
}

.outOfTotal {
  margin-left: 5px;
}
