.paymentMethod {
  display: flex;
  align-items: center;
}

.icon {
  height: 24px;
  width: 24px;
}

.bordered {
  border: 1px solid var(--color-grey2);
  border-radius: var(--border-radius-xs);
}
