.filter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    border: 1px solid var(--color-grey2);
    box-shadow: 0 6px 8px -4px rgb(85 88 92 / 24%);
    color: var(--color-text);
    font-size: var(--font-size);
    border-radius: 8px;
    gap: 0 !important;

    &:hover,
    &:focus {
        border: 1px solid var(--color-green2);
        background: white;
        color: var(--color-text);
    }
}

.row {
    min-width: 75px;

    &Mobile {
        min-width: 37px;
    }
}

.mobile {
    margin-right: 0;
}

.divider.divider {
    height: 24px;
}

.content {
    min-width: 360px;
}

.icon {
    transition: opacity 0.5s ease;
}

.isLoading {
    opacity: 0.4;
}

@media (max-width: 480px) {
    .root {
        right: 0 !important;
        left: auto !important;
    }
}
