.headerTabs {
    :global {
        .ant-tabs-tab {
            height: 46px;
        }
        .ant-tabs-nav-list {
            align-items: flex-end;
        }
        .ant-tabs-extra-content {
            margin-left: auto;
        }
    }

    @media screen and (min-width: 1120px) and (max-width: 1400px) {
        :global {
            .ant-tabs-nav {
                flex-direction: column-reverse;
                justify-content: space-between;
            }
        }
    }

    @media screen and (max-width: 1070px) {
        :global {
            .ant-tabs-nav {
                flex-direction: column-reverse;
                justify-content: space-between;
            }
        }
    }
}

.rangePickerFilterContainer {
  display: flex;
  justify-content: space-between;
}

.tableRow {
  :global {
    .ant-table-row {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.loading {
  :global {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        opacity: .4;
        pointer-events: none;
      }
    }
  }
}

