@mixin menuItem() {
    height: 44px !important;
    line-height: 44px !important;
    border-radius: 0px 8px 8px 0px;
    margin: 0 24px 4px 0 !important;
    padding: 0 12px 0 24px !important;
    width: auto;
    overflow: visible;

    :global(.ant-layout-sider-collapsed) & {
        margin-right: 0 !important;
        border-radius: 0;
    }

    :global(.ant-ribbon-wrapper) {
        position: static;
    }

    &:not(.ant-menu-item-selected):hover {
        color: var(--color-text);
        background-color: var(--color-grey1) !important;
    }

    a,
    a:hover {
        color: var(--color-text);
    }

    &:last-child {
        margin-bottom: 0 !important;
    }
}

@mixin menuItemSelected() {
    color: var(--color-text);
    background-color: var(--color-green1) !important;

    &:hover {
        color: var(--color-text);
    }

    &::after {
        display: none;
    }
}

.menu {
    :global {
        .ant-menu-item {
            @include menuItem();

            &-icon {
                display: block !important;
                font-size: 20px !important;
                width: 20px !important;
            }

            &-active {
                background-color: var(--color-grey1) !important;
            }

            &-selected {
                @include menuItemSelected();
            }
        }
        .ant-menu-title-content {
            margin-inline-start: 10px !important;
            font-size: var(--font-size-subtitle);
            color: var(--color-text);
        }
        .ant-menu-sub.ant-menu-inline {
            background-color: #fff;
        }
        .ant-menu-submenu {
            &-title {
                @include menuItem();
            }
        }
    }
}

:global {
    .ant-menu-submenu {
        .ant-menu-item {
            @include menuItem();
            & {
                padding-left: 54px !important; // Wrap in `& {}`
            }
            &-selected {
                @include menuItemSelected();
            }
        }
    }
}
