.root {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    flex-wrap: wrap;

    :global {
        .ant-btn > .ant-btn-icon + span {
            margin-left: 0 !important;
        }
    }
}

.divider {
    display: none;
}

:global(.ant-input-group).rangePicker {
    width: auto;
}

.statusSelect {
    margin-right: var(--margin-sm);
}

.dFlex {
    display: flex;
}

@media screen and (max-width: 1035px) {
    .divider {
        display: block;
        width: 100%;
        margin-top: var(--margin-sm);
    }

    .dFlex {
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-right: 0;
        max-width: 530px;
    }
}
