.container {
  padding: 0;

  :global {
    .ant-form-item-label {
      text-align: left;
    }
  }

  .form {
    max-width: 90vw;
  }
}

.formSpace {
  min-width: 100%;
}
