@use '~/src/assets/styles/vars' as *;

.previewImageLaptop {
    display: block;
    margin: 26px -10px -10px;
    width: 100%;
}

.previewImageMobile {
    display: block;
    margin: 26px -10px -10px;
}

.tabs {
    :global {
        .ant-tabs-nav {
            &::before {
                border: none;
            }

            &-wrap {
                justify-content: center;
            }
        }

        .ant-tabs-tab {
            span {
                @include icon-svg-fill(var(--color-grey7));
            }

            &-active {
                span {
                    @include icon-svg-fill(var(--color-secondary));
                }
            }
        }
    }
}
