@use '~/src/assets/styles/vars' as *;

.overlay {
    :global {
        .ant-popover-inner {
            padding: 0 !important;
        }
    }
}

.content {
    list-style: none;
    margin: 0;
    padding: 0;
}

.link {
    color: var(--color-text);
    font-weight: 400;

    &:hover {
        color: var(--color-text);
    }
}

.button {
    height: auto;
    color: var(--color-text);

    &:hover,
    &:focus {
        color: var(--color-text);
    }
}

.row {
    & > * {
        display: block;
        padding: calc(var(--margin-xs) + 2px) var(--margin-md);

        &:hover {
            background-color: var(--color-grey1);
        }
    }

    &:first-child {
        & > * {
            border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
        }
    }

    &:last-child {
        & > * {
            border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
        }
    }
}

.icon {
    transition: 0.2s;

    &:hover {
        opacity: 0.8;
    }
}

.iconHolder {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > img {
        display: block;
        width: 24px;
    }
}
