.sider {
    background: var(--color-white) !important;
    box-shadow:
        4px 0px 12px -8px rgba(85, 88, 92, 0.24),
        inset -1px 0px 0px #edeef2;
    z-index: 999;
}

.drawer {
    :global {
        .ant-drawer-body {
            padding: 0;
            height: 100vh;
        }
    }
}

.header {
    height: 64px;
    padding: 0 12px;
    display: flex;
    align-items: center;

    .mobile & {
        height: 56px;
    }

    & + .divider {
        margin-bottom: calc(var(--margin-md) + var(--margin-xs));

        .mobile & {
            margin-bottom: var(--margin-sm);
        }
    }
}

$divider-margin: calc(var(--margin-lg) - var(--margin-xs));

.divider {
    margin: var(--margin-sm) var(--margin-lg);
    width: calc(100% - 48px);
    min-width: calc(100% - 48px);

    :global(.ant-layout-sider-collapsed) & {
        margin: 0 $divider-margin $divider-margin $divider-margin;
        width: calc(100% - 40px);
        min-width: calc(100% - 40px);
    }
}

.help {
    position: fixed;
    margin-bottom: calc(var(--padding-md) + 4px);
    bottom: 40px;
    width: 279px;

    &Collapsed {
        width: 64px;
    }
}

.logos {
    margin: calc(var(--padding-md) + 4px) calc(var(--padding-md) + 8px)
        calc(var(--padding-sm) + 4px);
    width: 200px;
    height: 40px;
}

.content {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    & > * {
        flex-shrink: 0;
    }
}

.bottomMenu {
    position: fixed;
    margin-bottom: var(--padding-md);
    bottom: 0px;
    width: 304px;
    &Collapsed {
        width: 64px !important;
    }
}
