.grid {
  &Status {
    display: flex;
    align-items: center;
  }

  &Divider {
    margin-left: -16px;
    margin-right: 16px;
  }
}
