/**
COMMON STYLES
 */
.body {
  padding: 0;
}

.header {
  :global(.ant-page-header-heading) {
    align-items: center;
  }
}

.seeReports {
  font-size: var(--font-size);
}
