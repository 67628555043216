
.row {
    display: flex;
    justify-content: center;

    > * {
        width: calc(50% - 16px);
        &:last-child {
            margin-left: 16px;
        }
    }
}

.modal {
    :global {
        .ant-modal-footer {
            .ant-btn {
                width: 200px;

                & + .ant-btn {
                    margin-left: 16px;
                }
            }
        }

        .ant-form {
            .ant-btn {
                width: 200px;
            }
        }
    }
}
