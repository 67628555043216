.message {
  padding: 14px;
  margin-top: 8px;
  background-color: var(--color-grey8);
  border-radius: var(--border-radius-md);

  &:after {
    content: "";
    position: absolute;
    border: 16px solid transparent;
    border-bottom: 12px solid var(--color-grey8);
    border-left: none;
    bottom: 0;
    right: 8px;
  }
}
