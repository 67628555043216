.header {
    position: relative;
    background: #fff;
    box-shadow: 0px 4px 12px -8px rgba(85, 88, 92, 0.24), inset 0px -1px 0px #EDEEF2;
    padding: 10px 24px;
    line-height: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 64px;

    &.mobile {
        padding: 6px 16px 6px 10px;
        height: 56px;
    }
}

.logout {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
}

.icon {
    font-size: 18px;
    line-height: 0 !important;
    &:hover {
        cursor: pointer;
    }
}

.divider {
    $margin: calc(var(--margin-lg) - var(--margin-xs));

    height: 24px;
    margin: 0 $margin;
}

.language {
    margin-right: calc(var(--margin-md) - 6px);
}