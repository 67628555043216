.monthPickerWrapper {
  margin-bottom: 10px;
  :global {
    .ant-picker-input > input {
      max-width: 100px;
    }
  }
}

@media (max-width: 576px) {
  .monthPickerWrapper {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  :global {
    .ant-picker-panel-container .ant-picker-panels {
      display: inline-block;
      width: 280px;
    }
  }
}


