.root {
    padding: 28px 24px;
    background: #fff;
    height: 100%;

    :global {
        span.ant-typography {
            line-height: 1;
        }
    }
}
