.ecospendText {
    font-size: 13px;    
    color: #9B9B9B;
    text-align: center;

    a, a:hover {
        color: #555;
        text-decoration: underline !important;
    }
}
