@use '~/src/assets/styles/vars' as *;

.listItem {
    width: 100%;
    border-radius: 0;
    margin-bottom: 4px;

    &Title {
        @include for-phone-only {
            font-size: 14px;
        }
    }
}
