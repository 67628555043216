@use '~/src/assets/styles/vars' as *;

.pieChart {
  position: relative;
  @include for-phone-only {
    display: flex;
    justify-content: center;

    canvas {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @include for-tablet-up {
    display: flex;
    justify-content: center;

    canvas {
      max-width: 300px;
      max-height: 300px;
    }
  }

  .chart {
    position: relative;
    margin: auto;
    padding-bottom: 14px;
  }

  &.hasLegend .chart {
    min-height: 250px;
    max-height: 300px;
    max-width: 300px;
  }

  .legendWrapper, .legendContainer {
    display: flex;
    align-items: center;
  }

  .legend {
    padding: 0;
    margin-bottom: var(--margin-md);
    list-style: none;
    li {
      cursor: pointer;
      margin-bottom: var(--margin-md);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .line {
    display: inline-block;
    width: 1px;
    height: 16px;
    margin-right: var(--margin-sm);
  }

  .legendTitle {
    display: flex;
    align-items: center;
    color: var(--color-text);
    word-break: break-word;
    margin-bottom: 0;
  }

  .legendIcon {
    font-size: 34px;
    line-height: 20px;
    margin-top: -5px;
    margin-right: var(--margin-sm);
    height: 25px;
    width: 25px;
    border: 1px solid var(--color-grey6);
    border-radius: var(--border-radius-sm);
  }

  .legendSubTitleWrapper {
    margin-top: -17px;
  }

  .legendSubTitle {
    color: var(--color-text);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }

  .title {
    margin-bottom: var(--margin-sm);
  }
}

.legendBlock {
  &.hasLegend .chart {
    width: 100%;
    margin: 0 0 var(--margin-xl) 0;
  }

  .legend {
    position: relative;
    transform: none;
  }
}

@include for-desktop-up {
  .pieChart {
    padding-top: 0;

    .legendWrapper {
      display: block;
    }

    .legend {
      width: 100%;
    }
  }
}

@include for-phone-only {
  .pieChart {
    padding-top: var(--padding-xs);
  }
}

@media screen and (max-width: 922px) {
  .pieChart {
    display: block;
  }
}


