.isMobile {
  background-color: white;
}

.title {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: var(--font-size);

  &Mobile {
    justify-content: space-between;
  }

  &Text {
    margin-right: 10px;
  }
}
