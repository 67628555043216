@use '~/src/assets/styles/vars' as *;

.title {
    @include for-phone-only {
        font-size: 20px !important;
    }
}

.cancelPayment {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &Divider {
        height: 16px;
        margin: 0 16px;
    }
}

.btn,
.extra {
    margin-top: var(--margin-md);
    margin-right: var(--margin-sm);

    &:last-child {
        margin-right: 0;
    }
}
