.links {
  text-align: center;
  margin-bottom: var(--margin-md);

  > a {
    display: inline-block;
    margin: 0 var(--margin-md);
    font-size: var(--font-size);
  }
}
