@use '~/src/assets/styles/vars' as *;

.expandIcon {
  @include icon-svg-fill(var(--color-grey4));
}

.panel {
  font-size: var(--font-size-subtitle);
  color: var(--color-text);
}
