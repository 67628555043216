@use "sass:map";

$dimensions: (
        desktop: (
                width: 450px,
                preview-content: 300px
        ),
        mobile: (
                width: 350px,
                preview-content: 250px
        )
);

$chat-height: 550px;
$padding: (
        left: 20px,
        right: 16px
);
$chat-input-min-height: 50px;

@mixin fixed-dimensions($width, $height: null) {
  @if $width {
    max-width: $width;
    min-width: $width;
  }
  @if $height {
    max-height: $height;
    min-height: $height;
  }
}

%header-common {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

%scrollable {
  overflow-y: scroll;
}

@mixin chat-container($type: desktop) {
  $width: map.get(map.get($dimensions, $type), 'width');
  $preview-content: map.get(map.get($dimensions, $type), 'preview-content');
  $message-width: if($type == desktop, 340px, 250px);

  :global {
    .sendbird-channel-list__header {
      @extend %header-common;
    }

    .sendbird-app__channellist-wrap {
      @include fixed-dimensions($width);
      @extend %scrollable;
    }

    .sendbird-channel-list__body,
    .sendbird-channel-list,
    .sendbird-conversation,
    .sendbird-app__settingspanel-wrap {
      @include fixed-dimensions($width, $chat-height);
    }

    .sendbird-channel-header,
    .sendbird-channel-preview {
      @include fixed-dimensions($width);
      @if $type == desktop {
        padding: {
          left: map.get($padding, 'left') !important;
          right: map.get($padding, 'right') !important;
        }
      }
    }

    .sendbird-channel-preview {
      .sendbird-channel-preview__content {
        .sendbird-channel-preview__content__lower,
        .sendbird-channel-preview__content__upper {
          width: $preview-content;
        }
      }
    }

    @if $type == desktop {
      .sendbird-conversation__messages {
        @extend %scrollable;
      }

      .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__channel-name {
        max-width: 150px;
      }

      .sendbird-message-content-menu__edit-button,
      .sendbird-user-message__more__menu-item--edit,
      .sendbird-message-action-menu .sendbird-message-action-menu__edit {
        display: none !important;
      }
    }

    .sendbird-message-content .sendbird-message-content__middle, .sendbird-thumbnail-message-item-body, .sendbird-message-content__middle__message-item-body {
      max-width: $message-width !important;
    }

    .sendbird-thumbnail-message-item-body {
      min-width: $message-width !important;
    }

    .sendbird-message-input {
      min-height: $chat-input-min-height;
    }

    .sendbird-image-renderer__image {
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }
}

.chatContainer {
  @include chat-container(desktop);
}

.chatContainerMobile {
  @include chat-container(mobile);
}
