.ecospendPayeeBlock {
    display: flex;
    width: 100%;
}

.block {
    flex-grow: 1;
    &:first-child {
        margin-right: 12px;
    }
}

@media screen and (max-width: 767.9px) {
    .ecospendPayeeBlock {
        flex-wrap: wrap;
    }

    .block {
        width: 100%;
        &:first-child {
            margin: 0 0 12px;
        }
    }
}