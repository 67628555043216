.maskedNumber {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dots {
  font-size: 1.6em;
  letter-spacing: 0.2em;
  color: black;
  content: '••••';
  font-weight: bold;
  line-height: 1;
  vertical-align: middle;
  display: inline-block;
}

.dots::before {
  content: '••••';
  font-weight: bold;
}
