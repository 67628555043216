.settlementsTableFilter {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.filterButtonContainer {
  margin-right: 10px;
}

.filterButtonContainerMobile {
  margin-left: 10px;
}

.divider {
    display: none;
}

@media screen and (max-width: 910px) {
    .filterButtonContainer {
        margin-right: 0;
    }

    .divider {
        display: block;
        width: 100%;
        margin-top: var(--margin-sm);
    }
}