.pageHeader {
    :global {
        .ant-page-header {
            padding: 16px 24px;
        }
        .ant-page-header-heading {
            margin-bottom: 20px;
        }
        .ant-page-header-content {
            padding-top: 0px;
            margin-top: -10px;
        }

        .ant-tabs-nav {
            margin: 0 0 16px !important;
        }
    }
    &.noMargin {
        :global {
            .ant-page-header-heading {
                margin-bottom: 0px;
            }
        }
    }
    &Mobile {
        :global {
            .ant-page-header {
                padding: 16px;
            }
            .ant-page-header-heading-left {
                width: 80%;
            }
        }
    }
}

.bottomBorder {
    height: 1px;
    width: calc(100% - 48px);
    border-bottom: 1px solid var(--color-grey6);
    margin: -32px auto 20px;
}
