.avatar {
    height: 46px !important;
    width: 46px !important;
    border: none !important;
}

.descriptionLabel {
    :global {
        .ant-descriptions-item-label {
            color: var(--color-grey4) !important;
            min-width: 264px;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: -4px;
        }

        .ant-descriptions-item-content {
            font-size: 12px;
            font-weight: 400;
        }

        .ant-descriptions-item {
            padding-bottom: 6px !important;
        }

        .ant-descriptions-row {
            padding-bottom: 10px;
        }
    }
}

.card {
    box-shadow: 0px 4px 16px 0px rgba(85, 88, 92, 0.08);
    max-width: 800px;
}

.rightIcon {
    position: absolute;
    right: 20px;
    top: 24px;
    svg {
        fill: var(--color-grey4);
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 550px;
    width: 100%;
    display: block;
    font-size: 12px;
    margin-bottom: 8px;
}

.changeBankDetails {
  svg {
    font-size: 20px;
  }

  span {
    font-size: var(--font-size-small);
  }

  span {
    &:last-child {
      margin-left: 5px;
    }
  }
}
