.copy {
  svg {
    path {
      fill: var(--color-text);
    }
  }
}

.copyLink {
  display: flex;
}

.input {
  margin-right: 16px;
  color: var(--color-secondary);
}

.btn.btn {
  min-width: 44px;
  max-width: 44px;
  height: 44px;
  padding: 0;
}
