.tag {
  margin-top: 6px;

  svg {
    width: 14px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }
}
