@use '~/src/assets/styles/vars' as *;

.card {
  max-width: 248px;
  max-height: 152px;
  box-shadow: var(--base-shadow);
  border: 1px solid var(--color-grey2);

  :global(.ant-card-body) {
    padding: calc(var(--padding-md) - 1px) calc(var(--padding-xl) - var(--padding-sm));
  }

  &Active {
    border: 1px solid var(--color-green2);
    background-color: var(--color-green1);
  }
}

.chip {
  padding-bottom: var(--padding-sm);

  &Active {
    @include icon-svg-fill(white);
  }
}

.data {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.text {
  color: var(--color-grey5);
}

.logo {
  img {
    height: 20px;
  }
}

.lastRow {
  width: 100%;
}
