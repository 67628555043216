.PostalCodeDropDown {
    overflow: hidden;
    
    :global .ant-dropdown-menu {
        max-height: 250px;
        overflow: auto;
        padding: 0;
    }

    :global .ant-dropdown-menu-item {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        padding: 15px 14px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: normal;
        &:hover {
            background-color: #F4F4F4 !important;
        }
    }
}
