.footer {
	padding: 30px 0;
	height: 120px;
	background: white;
	text-align: center;

	&Mobile {
		padding: var(--padding-lg);
	}
}

.version {
	font-size: var(--font-size-extra-small);
}
