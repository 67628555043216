.root {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
}

.selectWrapper, .filter {
  margin-right: var(--margin-sm);
}

.divider {
    display: none;
}


@media screen and (max-width: 980px) {
    .root {
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-right: 0;
    }

    .divider {
        display: block;
        width: 100%;
        margin-top: var(--margin-sm);
    }

    .selectWrapper {
        margin-right: 0;
    }
}
