.mainWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.contentWrapper {
  display: flex;
  justify-content: center;
}

.previewImg {
  height: 350px;
  object-fit: contain;
}

.notificationWrapper {
  width: 375px;
  height: 350px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.notificationTitle {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 400;
}

.notificationDescription {
  font-size: 16px;
  font-weight: 400;
  white-space: pre-line;
  margin-top: 5px;
}

.logo {
  height: 40px;
  width: auto;
  margin-bottom: 54px;
}

@media screen and (max-width: 740px) {
  .contentWrapper {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
  }

  .logo {
    display: none;
  }

  .notificationWrapper {
    width: 100%;
    height: auto;
    margin-top: 50px;
    margin-right: 0px
  }

  .previewImg {
    height: 300px;
  }

  .notification {
    text-align: center;
    width: 100%;
  }

  .notificationTitle {
    margin-bottom: 0;
    font-size: 32px;
    font-weight: 600;
  }

  .notificationDescription {
    font-size: 16px;
    font-weight: 400;
  }
}
