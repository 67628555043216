@use '~/src/assets/styles/vars' as *;

.description {
    margin-bottom: 16px;

    :global {
        .ant-typography {
            justify-content: end;
        }
    }

    &Mobile {
        :global {
            .ant-typography {
                justify-content: start;
            }
        }
    }
}

.row {
    :global {
        .ant-typography {
            font-size: var(--font-size-subtitle);
            line-height: 1.5 !important;

            &-copy {
                @include icon-svg-fill(var(--color-grey7));
            }

            @include for-phone-only {
                font-size: 14px !important;
            }
        }
    }
}

.column {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grey {
    color: var(--color-grey4);
}
