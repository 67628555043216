.container {
  margin-bottom: var(--margin-xl);
}

.income {
  margin-right: var(--margin-xl);
  svg {
    path {
      fill: var(--color-green2);
    }
  }
}

.outgoing {
  margin-right: var(--margin-xl);
  svg {
    path {
      fill: var(--color-red1);
    }
  }
}

.text {
  font-weight: 400;
  font-size: var(--font-size-subtitle);
}
