.container {
    max-width: 800px;
    padding: 0 !important;
}

.bankAccount.bankAccount {
    margin-top: 24px;
    margin-bottom: 24px;
}

.descriptionLabel {
    :global {
        .ant-descriptions-item-label {
            min-width: 264px;
        }
    }
}

.descriptionLabelMobile {
    :global {
        .ant-descriptions-item-label {
            color: var(--color-grey4);
        }

        .ant-descriptions-item {
            padding-bottom: 10px;
        }

        .ant-descriptions-row {
            padding-bottom: 6px;
        }
    }
}
