.copyText {
    margin: 2px 0 0 5px;
}

.resultIcon {
    display: block;
    font-size: 32px;
    color: rgb(75, 185, 170);
    margin: 0 auto 20px;
}
.resultIcon.hasError {
    color: #dc3545 !important;
}

.title {
    font-size: 24px;
    font-weight: normal;
    margin: 0 0 10px 0;
    text-align: center;
}

.errorTitle {
    color: #dc3545 !important;
    text-transform: uppercase;
}

.subtitle {
    font-size: 14px !important;
    margin: 0 0 30px;
}

.block {
    margin-bottom: 12px;
}

.largeBlock {
    margin-bottom: 12px;
    padding: 12px 20px !important;
    text-align: center;
}

.transactionContent {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.buttons {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
}

.button {
    font-size: 14px;
    width: 100%;
    padding: 10px 15px;
    text-transform: none;
}

.footertext {
    font-size: 10px;
    margin: 24px 0 0 0;
}

.payeeBlock {
    margin-bottom: 35px;
}

.description {
    margin: 0 0 12px;
    font-size: 12px;
    color: #9B9B9B;
    font-weight: 400;
    text-align: center;
}

.nowrap {
    white-space: nowrap;
}

@media screen and (max-width: 767.9px) {
    .ecospendResultPage {
        padding-top: 24px;
    }
}
