.item {
    display: flex !important;
    flex-direction: row;
    padding-right: 20px !important;
    margin-bottom: 0 !important;
    align-items: start !important;

    &Mobile {
        padding-right: 0 !important;
    }

    & .dividerMobile {
        height: 1px;
        margin: 20px 0;
    }

    & .contentMobile {
        min-width: 100%;
    }
}

.content {
    min-width: 85%;
}

.selectWrapper {
    margin-bottom: 15px;
}

.ifNoPercentTrend {
    height: 24px; // height of PercentTrend
}

.title {
    &#{&} {
        margin-top: 0;
        margin-bottom: 2px;
    }
}

.divider {
    margin: 0 20px;
    height: 100px;
}

.icon {
    color: var(--color-green2);
}

.infoIcon {
    display: inline-block !important;
}
