.headerWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footerWrapper {
  display: flex;
  justify-content: end;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.description {
  white-space: pre-line;
}
