@use '~/src/assets/styles/vars' as *;

.tableParams {
  display: flex;
  justify-content: space-between;
}

.tableView {
  display: flex;
  justify-content: space-between;

  :global(.ant-radio-button-wrapper-checked) {
    span {
      span {
        @include icon-svg-fill(#fff);
      }
    }
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
