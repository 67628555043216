@use '~/src/assets/styles/vars' as *;

.buttonContainer {
    display: flex;
}

.btn.btn {
    min-width: 44px;
    max-width: 44px;
    height: 44px;
    padding: 0;
}

.email {
    margin-right: 16px;
}

.icon {
    @include icon-svg-fill-hover(var(--color-text));
}

@media screen and (max-width: 910px) {
    .buttonContainer {
        margin-left: -10px;
    }
}
