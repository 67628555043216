.menu {
  :global {
    .ant-menu {
      &-root {
        border: 1px solid var(--color-grey2) !important;
        box-shadow: 0 4px 16px rgba(85, 88, 92, 0.08) !important;
        border-radius: 12px;
      }
    }

    .ant-dropdown-menu {
      padding: var(--padding-xs);
    }
  }
}
