.mobile {
  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Title {
    color: var(--color-secondary);
  }
}

.verticalDivider {
  height: 2em;
}
