.tableParams {
  display: flex;
  justify-content: space-between;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
