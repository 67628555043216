.codes{
    margin-top: 20px
}

.footer{
    margin-top: 20px;

    & > ul {
        color: var(--color-yellow3);
        padding: 0 0 0 18px;
        margin: 10px 0 0;

        li{
            margin-bottom: 5px;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.buttonHolder{
    position: absolute;
    right: 16px;
    top: 50%;
    margin-top: -10px;
 }
 
 .icon{ 
     font-size: 16px;
     cursor: pointer;
 }
 
 .listHolder{
     position: relative;
 }