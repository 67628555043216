.list{
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
        margin-bottom: 15px;

        &:last-child{
            margin-bottom: 0;
        }

        code{
            white-space: nowrap;
            background-color: var(--color-green1);
            padding: 5px;
            border-radius: var(--border-radius-sm);
        }
    }
}

.formItem{
    margin: 0;
}

.qrHolder{
    text-align: center;
}