.filterContent {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
}

.select {
  min-width: 150px;

  :global(.ant-select-selector) {
    border-radius: var(--border-radius-sm) !important;
    box-shadow: 0 6px 8px -4px rgba(85, 88, 92, 0.24) !important;
    border: 1px solid var(--color-grey2) !important;
  }
}
