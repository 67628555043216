.body {
  padding: 0 calc(var(--padding-sm) + var(--padding-md));
}

.skeleton {
  margin-top: var(--margin-lg);
}

.creditCard {
  min-width: 248px;
  margin-right: var(--margin-md);
  margin-bottom: var(--margin-md);
}

.management {
  display: flex;
  justify-content: space-between;

  &Mobile {
    justify-content: start;
    flex-direction: column;
  }
}

.collapse {
  margin: 0 calc(0px - var(--margin-lg));
  :global {
    .ant-collapse-header.ant-collapse-header {
      padding-left: var(--padding-lg);
      padding-right: var(--padding-lg);
      background: var(--color-grey2);
      border-bottom: 0;
    }

    .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      right: 24px;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
}

.divider {
  height: 16px;
  margin: 0;
  border-top: 0;
}

.sum {
  margin-right: calc(var(--margin-xl) + var(--margin-xl) + var(--margin-xl));

  &Mobile {
    margin-right: calc(var(--margin-xl) + var(--margin-xl));
  }
}
