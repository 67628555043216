.ecospendBackLink {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    color: #4D4C4C;

    &:hover {
        color: #000;
    }

    i {
        margin-right: 8px;
    }
}