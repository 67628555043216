.headerTabs {
    :global {
      .ant-tabs-nav {
        display: flex;
        flex-direction: column-reverse;
      }
      .ant-tabs-extra-content {
        align-self: flex-end;
      }
    }
}

.headerTabsMobile {
  :global {
    .ant-tabs-nav::before {
      width: 100%;
    }

    .ant-tabs-nav-list {
      width: 100%;
    }
  }
}

.tabsExtraContent {
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--margin-md) - var(--margin-xs));
}

.tableRow {
  :global {
    .ant-table-row {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
