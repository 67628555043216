.notificationContainer {
  position: relative;
  width: 100%;
  padding: 20px 24px;
  border-radius: 12px;
  display: flex;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.icon {
  font-size: 17px;
  font-weight: bold;
  stroke-width: 100;
}

.info {
  background: #C7DDFF;
  color: #3D5F92;

  .icon {
    color: #3D5F92;
  }
  .closeButton {
    background-image: url("/src/assets/img/notifications/close-buttons/close_blue.svg")
  }
}

.success {
  background: #69B6AA;
  color: #fff;

  .icon {
      color: #fff;
  }

  .closeButton {
    background-image: url("/src/assets/img/notifications/close-buttons/close_green.svg")
  }
}

.warning {
  background: #FACF1A;

  .icon {
    color: #1D1737;
  }

  .closeButton {
    background-image: url("/src/assets/img/notifications/close-buttons/close_gray.svg")
  }
}

.error {
  background: #EA6262;
  color: #fff;

  .icon {
    color: #fff;
  }

  .closeButton {
    background-image: url("/src/assets/img/notifications/close-buttons/close_red.svg")
  }
}

.title {
  font-weight: bold;
}

.description {
  word-break: break-word;
  white-space: pre-line;
}

.closeButton {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
