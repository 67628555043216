.link {
    margin-left: calc(var(--margin-xl) - 10px);
}

.tabs {
    :global(.ant-tabs-content-holder) {
        border-right: none;
    }

    :global(.ant-tabs-tab) {
        margin: var(--margin-md) 0 0 0 !important;
    }

    :global {
        .ant-tabs-ink-bar.ant-tabs-ink-bar {
            width: 4px;
            background: var(--color-green2);
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: var(--color-text);
        }
    }

    &Desktop {
        :global(.ant-tabs-nav) {
            min-width: 500px;
        }
    }
}

.tabPane {
    :global {
        .ant-collapse-content-box {
            padding-left: 0 !important;
        }
    }
}
