.card {
  width: 100%;
  margin-top: var(--margin-lg);

  :global {
    .ant-card-head-title {
      font-size: var(--font-size-title);
      font-weight: bold;
    }
  }
}

.statistic {
  :global {
    .ant-statistic-content-value {
      font-family: Poppins, serif;
    }
  }
}

.greek-lang{
    .statistic {
        :global {
            .ant-statistic-content-value {
              font-family: SegoeUI, serif;
            }
        }
    }
}

.divider {
  height: 55px;
  margin: 0 calc(var(--margin-xs) * 2 + var(--margin-sm));
}

.button {
  height: 44px;
}
