.footer {
  display: flex;
  justify-content: center;
}

.field {
  margin-bottom: 0;
}

.cancel {
  margin-right: 16px;
}
