.list {
  margin: 0 calc(0px - var(--margin-lg));
}

.loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: white;
}

.listItem {
  background: white;
  padding: var(--padding-lg);

  :global {
    .ant-list-item-meta {
      align-items: center;
    }
  }
}

.groupTitle {
  height: 28px;
  font-size: var(--font-size-small);
  color: var(--color-grey5);
  background: var(--color-grey2);
}

.avatar {
  margin-right: calc(var(--margin-lg) - 4px);
}
