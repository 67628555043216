.buttonsHolder {
    display: flex;
    justify-content:flex-end
}

.buttonsFull{
    display: block;

    button{
        width: 100%;
    }

    :global{
        .ant-space{
            display: block;
        }

        .ant-space-item{
            margin-bottom: 10px;

            &:last-child{
                margin-bottom: 0;
            }
        }       
    }
}

.form{
    margin-top: 20px;
}

