.tag {
    border-radius: var(--border-radius-xs) !important;
    border: none;
    margin-right: 0;
    padding: 0 5px;
}

.green {
    color: var(--color-secondary);
    background: var(--color-green1);
}

.red {
    color: var(--color-red1);
    background: var(--color-red2);
}
