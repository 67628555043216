@use 'vars' as *;
@forward 'fonts';
@forward 'antd';
@forward 'antd.responsive';

* {
    box-sizing: border-box;
}

body {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size);
    line-height: var(--line-height);
    background-color: var(--color-grey1);
    margin: 0px;
    height: 100%;

    &.greek-lang {
        font-family: SegoeUI, serif;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
}

::placeholder {
    color: var(--color-grey4) !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-grey4) !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-grey4) !important;
}

a {
    color: var(--color-secondary);
    text-decoration: none;
    &:hover {
        color: var(--color-green2);
    }
}

.grow {
    flex-grow: 1;
}

.nowrap {
    white-space: nowrap;
}

.amount {
    white-space: nowrap;
    color: #1d1737;
    font-size: var(--font-size-title);
    font-weight: 600;
}

.reset-table {
    border: 0;
    background: none;
    box-sizing: border-box;
    border-collapse: collapse;
    border-spacing: 0;
    padding: 0;
}
