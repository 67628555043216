.invoicesHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0 !important;
    :global {
        .ant-page-header-content {
            padding-top: 12px;
            margin-top: 0;
        }
        .ant-page-header-heading {
            margin-bottom: 0;
        }
    }
}

.invoiceDivider {
    height: 1px;
    width: calc(100% - 48px);
    border-bottom: 1px solid var(--color-grey6);
    margin: 0 auto 20px;
}

@media (max-width: 576px) {
    .invoicesHeader {
        display: block;
        :global {
            .ant-page-header-content {
                padding-top: 0;
            }
        }
    }
}
