.burger {
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-green1);
        color: var(--color-green2);
    }

    &:active {
        background-color: transparent;
        color: var(--color-secondary);
    }
}