@use '~/src/assets/styles/vars' as *;

.headerTabsMobile {
    :global {
        .ant-tabs-nav::before {
            width: 100%;
        }

        .ant-tabs-nav-list {
            justify-content: space-between;
            width: 100%;
        }
    }
}

.tabs {
    :global {
        .ant-tabs-nav {
            margin: 0 var(--margin-lg) 16px !important;
        }
    }

    @media screen and (max-width: 1290px) {
        :global {
            .ant-tabs-nav {
                flex-direction: column-reverse;
                justify-content: space-between;
                align-items: flex-end;
            }
        }
    }

    @include for-phone-only {
        :global {
            .ant-tabs-nav {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
    }
}

.rangePickerFilterContainer {
    display: flex;
    justify-content: space-between;
}

.pageHeaderExtra {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 12px;

    .divider {
        height: 20px;
    }
}

.pageSizeControlContainer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    align-content: flex-start;
}

.buttons {
    display: flex;
    align-items: center;
}
