.title {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.headerMobile {
  :global(.ant-card-body) {
    padding-top: 0;
  }
}

.row {
  :global(.recharts-wrapper) {
    margin: auto;
  }
}
