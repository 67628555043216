@use '~/src/assets/styles/vars' as *;

.card {
  background-image: url("/src/assets/img/overview/paymentLinksBackground.png"), url("/src/assets/img/overview/yellow-circle.svg");
  background-size: 150px, 364px;
  background-repeat: no-repeat;
  background-position:
          right 0 bottom 0,
          right -120px top -20px;

  &Mobile {
    background-image: url("/src/assets/img/overview/paymentLinksBackgroundMobile.png"), url("/src/assets/img/overview/yellow-circle.svg");
    background-position:
            right 0 top 25px,
            right 20px top -180px;
    background-size: 200px, 344px;
  }

  &Yellow {
    background-image: url("/src/assets/img/overview/paymentLinksBackgroundMobile.png"), url("/src/assets/img/overview/yellow-circle.svg");
  }

  &Blue {
    background-image: url("/src/assets/img/overview/paymentLinksBackgroundMobile.png"), url("/src/assets/img/overview/blue-circle.svg");
  }

  @include for-tablet-up {
    background-size: 170px, 364px;
  }

  @include for-desktop-up {
    background-size: 230px, 400px;
  }

  .title {
    font-size: var(--font-size-subtitle);
    color: var(--color-grey4);
  }

  .subtitle.subtitle.subtitle {
    margin-top: 16px;

    &Mobile {
      font-size: 20px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    max-width: 315px;

    &Mobile {
      margin-top: 12em;
    }
  }

  .text {
    margin-bottom: 16px;
  }

  .link {
    display: flex;
    align-items: center;

    @include icon-svg-fill-hover(var(--color-green2));
  }
}
