@use '~/src/assets/styles/vars' as *;

.drawer {
    :global {
        .ant-drawer-body {
            padding-top: 0;
        }
    }
}

.subTitle {
    color: var(--color-grey4);
    margin-right: 3px;
}
