$container-spacing: 20px;
$z-layer: 1000;

.container {
  position: fixed;
  bottom: $container-spacing;
  right: $container-spacing;
  z-index: $z-layer;

  :global {
    .ant-popover {
      &-inner-content {
        padding: 0;
      }

      &-arrow {
        display: none;
      }
    }
  }
}
