@use '~/src/assets/styles/vars' as *;

.input {
  max-width: 40px;
  box-shadow: var(--base-shadow);

  :global(.ant-card-body) {
    padding: calc(var(--padding-md) - 1px) calc(var(--padding-xl) - var(--padding-sm));
  }
}
