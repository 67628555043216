.footer {
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-xs);
  :global(.ant-form-item-control-input-content) {
    display: flex;
    justify-content: center;
  }
}

.close {
  margin-right: var(--margin-sm);
}
