@use '~/src/assets/styles/vars' as *;

.overlay {
    :global {
        .ant-popover-inner {
            padding: 0 !important;
        }
    }
}

.content {
    min-width: 100px;
}

.row {
    display: flex;
    justify-content: space-between;
    padding: calc(var(--margin-sm) + 2px) var(--margin-md);
    cursor: pointer;

    &.active,
    &:hover {
        background-color: var(--color-grey1);
    }

    &:first-child {
        border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
    }

    &:last-child {
        border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
    }
}

.code {
    opacity: 0.5;
}

.icon {
    font-size: 20px;
    color: var(--color-grey7);

    @include icon-svg-fill-hover(var(--color-grey4));
}

.iconHolder {
    display: flex;
    align-items: center;
    cursor: pointer;
}
