@use 'vars' as *;

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
    color: var(--color-text);
}

.ant {
    &-radio {
        &:hover {
            .ant-radio-inner {
                border-color: var(--color-green2);
            }
        }

        &-inner {
            background-color: white;

            &::after {
                background-color: white;
            }
        }

        &-checked {
            border-color: white;

            .ant-radio-inner {
                background-color: var(--color-green2);
                border-color: var(--color-green2);

                &::after {
                    opacity: 1;
                    border: 1px solid white;
                }
            }
        }
    }

    &-input {
        &:focus {
            border-color: var(--color-green1) !important;
            box-shadow: 0 0 0 2px rgba(49, 126, 114, 0.2) !important;
            border-right-width: 1px;
            outline: 0;
        }
    }

    &-modal {
        font-family: Poppins, serif !important;

        &-content {
            border-radius: 12px !important;
            padding: 0 !important;
        }

        &-footer {
            display: flex;
            justify-content: center;
            padding: 10px 16px !important;
            text-align: right;
            border-top: 1px solid #f0f0f0 !important;
            border-radius: 0 0 12px 12px !important;
            margin: 0 !important;
        }

        &-header {
            padding: 16px 24px !important;
            border-bottom: 1px solid #f0f0f0 !important;
            border-radius: 12px 12px 0 0 !important;
            margin: 0 !important;
        }

        &-body {
            padding: 24px !important;
            line-height: 1.5715 !important;
            word-wrap: break-word;
        }

        &-title {
            font-weight: 500 !important;
            line-height: 22px !important;
        }

        &-close {
            &:hover {
                background-color: transparent !important;
            }
        }

        &-confirm {
            &-body {
                & > .anticon {
                    margin-inline-end: 16px !important;
                }
            }

            .ant-modal-body {
                padding: 32px 32px 24px !important;
            }
            .ant-modal-confirm-content,
            .anticon {
                margin-top: 8px !important;
            }

            .ant-modal-confirm-btns {
                margin-top: 24px !important;
            }

            .ant-btn {
                font-family: Poppins, serif !important;

                &-variant-outlined {
                    &:hover,
                    &:focus,
                    &-loading {
                        color: #fff !important;
                        background: var(--color-secondary) !important;
                        border-color: var(--color-secondary) !important;
                    }
                }
            }
        }
    }

    &-switch {
        min-width: 40px;
        height: 25px;

        &-checked {
            .ant-switch-handle {
                left: calc(100% - 20px - 2px) !important;
            }
        }

        &-handle {
            width: 20px !important;
            height: 20px !important;
        }
    }

    &-carousel {
        height: 100%;

        .slick-slider {
            height: 100%;

            .slick-list {
                height: 100%;

                .slick-track {
                    height: 100%;

                    .slick-slide {
                        height: 100%;

                        div {
                            height: 100%;
                        }
                    }
                }
            }
        }

        .slick-dots li button {
            background: var(--color-green3);
        }

        .slick-dots li.slick-active button {
            background: var(--color-green2);
        }
    }

    &-card {
        height: 100%;

        &-body {
            padding: 22px 24px !important;
        }

        &-head {
            &-title {
                padding: 16px 0;
                font-weight: 500;
            }
        }

        &-meta {
            &-avatar {
                padding-inline-end: 16px !important;
            }
        }
    }

    &-tabs {
        &-tab {
            & + & {
                margin: 0 0 0 16px !important;
            }

            &-active {
                .ant-tabs-tab-btn {
                    outline: none !important;
                }
            }
        }

        .ant-tabs-nav-list {
            transform: translate(0px, 0px) !important;
        }
        .ant-tabs-nav-operations,
        .ant-tabs-nav-wrap::before,
        .ant-tabs-nav-wrap::after {
            display: none !important;
        }
    }

    &-picker {
        height: 40px;

        &-cell {
            min-width: 24px !important;
            padding: 0 !important;
            border-radius: 0 !important;

            &::before {
                height: 36px !important;
            }

            &-inner {
                min-width: 36px !important;
                height: 36px !important;
                line-height: 36px !important;
                border-radius: 0 !important;
            }

            &-in-view {
                &.ant-picker-cell-today .ant-picker-cell-inner {
                    &::before {
                        border-radius: 0 !important;
                    }
                }

                &.ant-picker-cell-in-range {
                    &::before {
                        background: #c2e9e3 !important;
                    }
                }

                &.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before,
                &.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
                    background-color: #c2e9e3 !important;
                }
            }
        }

        &-header {
            height: 40px;

            &-view {
                line-height: 40px !important;
                button {
                    font-family: Poppins, serif !important;
                    font-weight: normal !important;
                    line-height: 40px !important;
                }
            }

            & > button {
                color: rgba(0, 0, 0, 0.25);
                line-height: 40px !important;
            }
        }

        &-dropdown {
            font-family: Poppins, serif !important;

            .ant-picker-time-panel-column
                > li.ant-picker-time-panel-cell-selected
                .ant-picker-time-panel-cell-inner {
                background: #69b6aa !important;
            }
        }

        &-range {
            &-arrow {
                inset-inline-start: auto !important;
                inset-inline-end: 11px;
            }
        }

        &-ranges {
            & > li {
                line-height: 46px !important;
            }
        }

        &-date {
            &-panel {
                width: 280px !important;
            }
        }

        &-time {
            &-panel {
                &-column {
                    width: 56px !important;
                    margin: 0 !important;
                }

                &-cell {
                    margin-inline: 0 !important;
                    &-inner {
                        width: 100% !important;
                        padding-inline-start: 14px !important;
                        border-radius: 0 !important;
                    }
                }
            }
        }

        &-body {
            padding: 8px 12px !important;
        }

        &-content {
            th {
                height: 30px !important;
                line-height: 30px !important;
            }
        }
    }

    &-tag {
        width: auto;
        height: 24px;
        max-width: 150px;
        display: flex;
        font-size: 14px;
        font-weight: 400;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        border: none;
        padding: 0 14px;
        border-radius: 12px !important;

        &-green {
            background: var(--color-green1) !important;
        }
    }

    &-btn {
        display: inline-block;
        padding: 9px 20px;
        height: 40px !important;
        color: var(--color-secondary);
        border-color: var(--color-secondary);
        line-height: 1.5715 !important;
        gap: 5px !important;
        border-radius: 12px !important;

        &:hover,
        &:focus,
        &-loading {
            color: #fff;
            background: var(--color-secondary);
            border-color: var(--color-secondary);
        }

        &[disabled],
        &.disabled {
            &,
            &:hover,
            &:focus,
            &:active {
                color: var(--color-grey3);
                background: #fff;
                border-color: var(--color-grey3);
                text-shadow: none;
                box-shadow: none;
            }
        }

        &-loading {
            &::before {
                opacity: 0;
            }

            & > *:not(.ant-btn-loading-icon) {
                opacity: 0;
            }
        }

        &-loading-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-primary {
            color: var(--color-text);
            background: var(--color-primary);
            border-color: var(--color-primary);
            text-shadow: none;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;

            &:hover,
            &:focus {
                color: var(--color-text) !important;
                background: var(--color-primary-darker) !important;
                box-shadow: none;
                border-color: var(--color-primary);
            }

            &[disabled] {
                &,
                &:hover,
                &:focus,
                &:active {
                    color: #fff;
                    background: var(--color-grey3);
                    border-color: var(--color-grey3);
                }
            }
        }

        &-link,
        &-variant-link {
            border: none;

            &:hover,
            &:focus,
            &-loading {
                color: var(--color-green2) !important;
                background: #fff;
                border-color: #fff;
            }
        }

        &-sm {
            height: 40px;
        }

        &-dangerous {
            color: var(--color-red1);
            border-color: var(--color-red1);

            &:hover,
            &:focus {
                background-color: white;
                color: var(--color-red1);
                border-color: var(--color-red1);
            }
        }

        &-background-ghost {
            &:hover,
            &:focus {
                color: #fff !important;
                background: var(--color-secondary) !important;
                border-color: var(--color-secondary) !important;
            }
        }

        & > .ant-btn-icon + span {
            margin-left: 8px;
        }
    }

    &-drawer {
        &-close {
            position: absolute;
            right: 6px;
            top: 24px;

            &:active,
            &:hover {
                background-color: inherit !important;
            }
        }

        &-header {
            border-bottom: none !important;

            .ant-divider-vertical {
                margin: 0 8px !important;
            }
        }
    }

    &-select {
        &-dropdown {
            padding: 0 !important;

            .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
                background-color: var(--color-green1) !important;
            }

            .ant-select-item {
                border-radius: 0 !important;
            }
        }

        &-borderless {
            & .ant-select-selector {
                background-color: white !important;
            }
        }

        &-selector {
            height: 100% !important;
        }

        &-item {
            padding: 5px 12px !important;
            line-height: 22px;
            min-height: auto !important;
        }

        &-arrow {
            margin-top: -4px !important;
        }

        &-multiple {
            .ant-select-selection-item {
                height: 24px !important;
                line-height: 22px !important;
                border-radius: 12px !important;
            }
        }
    }

    &-notification {
        &-notice-success {
            padding: 16px 20px;
            border: 1px solid var(--color-green2);
            box-shadow: 0 8px 16px -8px rgba(85, 88, 92, 0.08);
        }

        &-notice-error {
            padding: 16px 20px;
            border: 1px solid var(--color-red1);
            box-shadow: 0 8px 16px -8px rgba(85, 88, 92, 0.08);
        }

        &-notice-close {
            display: none;
        }
    }

    &-pagination {
        &-disabled {
            color: var(--color-grey3);

            @include icon-svg-fill(var(--color-grey3));
            @include icon-svg-fill-hover(var(--color-grey3));
        }

        &-jump-next {
            color: var(--color-green2);
        }

        &-item {
            border: 1px solid #d9d9d9 !important;

            &-link-icon {
                color: var(--color-green2) !important;
            }

            &-notice-error {
                padding: 16px 20px;
                border: 1px solid var(--color-red1);
                box-shadow: 0 8px 16px -8px rgba(85, 88, 92, 0.08);
            }

            &-notice-close {
                display: none;
            }

            &-active,
            &:hover {
                border-color: var(--color-secondary) !important;
                transition: all 0.3s;
                background: #fff !important;

                a {
                    color: var(--color-secondary) !important;
                }
            }
        }
    }

    &-checkbox {
        &-inner {
            width: 16px !important;
            height: 16px !important;
            border-radius: 2px !important;

            &::after {
                width: 5.71428571px !important;
                height: 9.14285714px !important;
            }
        }
    }

    &-collapse {
        &-item > &-header,
        &-content-box {
            padding-left: 0;
        }

        &-header {
            border-bottom: 1px solid var(--color-grey6);
        }
    }

    &-list {
        .ant-row {
            align-items: baseline;
        }

        &-item {
            &-meta {
                align-items: flex-start;
                display: flex;
                flex: 1;
                max-width: 100%;

                &-avatar {
                    margin-right: 16px;
                }
            }
        }
    }

    &-popover {
        &-inner {
            padding: 12px 16px !important;
        }
    }

    &-divider {
        &-vertical {
            height: 14px;
            margin: 0 8px;
            vertical-align: middle;
        }
    }

    &-tooltip {
        &-inner {
            display: flex;
            align-items: center;
        }
    }

    &-typography {
        &span {
            line-height: 1 !important;
        }

        &-copy {
            color: var(--color-secondary) !important;
            font-size: inherit;

            &:hover {
                color: var(--color-text) !important;
            }
        }

        a {
            color: var(--color-secondary);

            &:hover {
                color: var(--color-text);
            }
        }

        &-success {
            color: var(--color-secondary) !important;
        }
    }

    &-table {
        &-cell {
            font-weight: 500 !important;
        }
    }

    &-page {
        &-header {
            .ant-divider-vertical {
                margin: 0 12px;
            }
        }
    }

    &-descriptions {
        &-row {
            td {
                padding-bottom: 16px !important;
            }
        }

        &-item {
            &-label {
                color: inherit !important;
            }
        }
    }

    &-spin {
        &-sm {
            .ant-spin-dot {
                font-size: 14px !important;
                margin: -7px !important;
                i {
                    width: 6px !important;
                    height: 6px !important;
                }
            }
        }

        &-dot {
            font-size: 20px !important;

            &-item {
                width: 9px !important;
                height: 9px !important;
            }
        }
    }

    &-dropdown {
        &-menu {
            padding: 4px 0 !important;
            border-radius: 12px !important;

            &-item {
                padding: 5px 12px !important;
                border-radius: 0 !important;
            }
        }
    }

    &-avatar {
        border: none !important;

        &-lg {
            width: 40px !important;
            height: 40px !important;
        }

        &-square {
            border-radius: 12px !important;
        }
    }
}

a.ant-typography,
.ant-typography a {
    color: var(--color-secondary) !important;
}
