.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderNumber {
  display: flex;
  align-items: center;
}
