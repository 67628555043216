.root {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: calc(var(--margin-sm) + var(--margin-xs));

    :global {
        .ant-btn > .ant-btn-icon + span {
            margin-left: 0 !important;
        }
    }
}

.divider {
    display: none;
}

.statusSelect {
    margin-right: var(--margin-sm);
}

@media screen and (max-width: 1230px) {
    .root {
        margin-right: 0;
    }

    .statusSelect {
        margin: var(--margin-sm) 0 0 0;
    }

    .divider {
        display: block;
        width: 100%;
        margin-top: var(--margin-sm);
    }
}

@media screen and (max-width: 575px) {
    .statusSelect {
        margin: var(--margin-sm) 0 0 var(--margin-sm);
    }
}
