.root{
    display: none;

    &.visible{
        display: block;
    }
}

.table{
    :global{
        .ant-table-thead > tr > th {
            background: transparent;
        }
    }
}

.pagination{
    display: flex;
    justify-content: flex-end;
}

.icon{
    font-size: 20px;
    margin-right: 15px;
}

.device{
    display: flex;

    align-items: center;
}