.banks {
    max-height: 300px;
    overflow-y: auto;
}

.input {
    font-size: 14px;
    margin-bottom: 20px;

    :global {
        .ant-btn {
            padding: 0;
        }
    }
}

.text {
    margin-bottom: var(--margin-md);

    ol {
        padding: var(--padding-xs) 0 var(--padding-xs) var(--padding-md);
        list-style-position: outside;
        font-size: var(--font-size-small);
    }
}

.center {
    text-align: center;
}

.bankContainer {
    position: relative;
    border-bottom: 1px solid #dedede;
    &:last-child {
        border-bottom: none;
    }
}

.bank {
    position: relative;
    padding: 15px 0;
    cursor: pointer;

    .arrow {
        position: absolute;
        top: 25px;
        right: 0;
    }

    &:hover {
        .arrow {
            opacity: 1;
        }
    }

    .bankText {
        color: #9B9B9B;
        font-size: 10px;
        font-weight: 400;
    }

    img {
        height: 20px;
        display: block;
    }
}
