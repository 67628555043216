.ecospendBankBlock {
    display: flex;
}

.bankField {
    margin-right: 15px;
    &:first-child {
        flex-grow: 1;
    }
    &:last-child {
        margin-right: 0;
    }
}

@media screen and (max-width: 767.9px) {
    .ecospendBankBlock {
        flex-wrap: wrap;
    }
    .bankField {
        &:nth-child(1) {
            width: 100%;
            margin: 0 0 8px;
        }
        &:nth-child(2), &:nth-child(3) {
            width: calc(50% - 15px);
        }
    }
}
