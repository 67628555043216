@use '~/src/assets/styles/vars' as *;

.root {
  :global(.ant-radio-button-wrapper-checked) {
    span {
      span {
        @include icon-svg-fill(#fff);
      }
    }
  }
}
