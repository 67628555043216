.geolocationMap {
  margin-top: var(--block-indent-main);
  height: 250px;
  min-width: 500px;
  position: relative;
  overflow: hidden;
  z-index:0;

  :global .leaflet-container {
    height: 250px;
    width: 100%;
  }
}
