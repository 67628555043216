:root {
    --color-primary: #facf1a;
    --color-primary-darker: #f2c400;
    --color-secondary: #317e72;

    --color-white: #fff;
    --color-grey1: #f9fafa;
    --color-grey2: #f1f2f2;
    --color-grey3: #dadbdc;
    --color-grey4: #959799;
    --color-grey5: #4b4f54;
    --color-grey6: #d7dde3;
    --color-grey7: #7f8286;
    --color-grey8: #f4f4f4;
    --color-grey9: #555555;

    --color-green1: #e9f4f3;
    --color-green2: #69b6aa;
    --color-green3: #317e72;
    --color-green4: #c2e9e3;

    --color-red1: #ea6262;
    --color-red2: #fbdada;

    --color-yellow1: #ffdf59;
    --color-yellow2: #ffdf5933;
    --color-yellow3: #ff9c1d;

    --color-blue1: #c7ddff;
    --color-blue2: #3d5f92;
    --color-blue3: #8ea7cc;

    --color-text: #1d1737;
    --color-box-shadow: rgb(85 88 92 / 24%);

    --font-size-extra-small: 12px;
    --font-size-small: 12px;
    --font-size: 14px;
    --font-size-title: 18px;
    --font-size-subtitle: 16px;
    --line-height: 1.5;
    --padding-left-right: 24px;
    --padding-xs: 4px;
    --padding-sm: 8px;
    --padding-md: 16px;
    --padding-lg: 24px;
    --padding-xl: 32px;
    --margin: 24px;
    --margin-xs: 4px;
    --margin-sm: 8px;
    --margin-md: 16px;
    --margin-lg: 24px;
    --margin-xl: 32px;
    --border-radius-xs: 4px;
    --border-radius-sm: 8px;
    --border-radius-md: 12px;

    --base-shadow: 0 4px 16px rgba(85, 88, 92, 0.08), inset 0px -1px 0px #edeef2;
    --base-shadow-1: 0 6px 8px -4px rgb(85 88 92 / 24%);
}

@mixin placeholder($color: var(--color-grey3)) {
    &::placeholder {
        color: $color !important;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color !important;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $color !important;
    }
}

@mixin icon-svg-fill($color) {
    & > span {
        & > svg {
            path {
                transition: all 0.3s;
                fill: $color;
            }
        }
    }

    & > svg {
        path {
            transition: all 0.3s;
            fill: $color;
        }
    }
}

@mixin icon-svg-fill-hover($color) {
    &:hover {
        & > span {
            & > svg {
                path {
                    transition: all 0.3s;
                    fill: $color;
                }
            }
        }

        & > svg {
            path {
                transition: all 0.3s;
                fill: $color;
            }
        }
    }
}

@mixin hover-cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet-up {
    @media (min-width: 600px) and (max-width: 768px) {
        @content;
    }
}

@mixin for-laptop-up {
    @media (min-width: 769px) and (max-width: 1199px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

@include for-desktop-up {
    :root {
        --font-size-title: 24px;
    }
}
