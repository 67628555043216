.modal {
    :global {
        .ant-modal-footer {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ant-tabs-nav {
            margin-top: 0 !important;
        }
    }
}

.close {
    margin-right: 16px;
}

.submit,
.close {
    margin-bottom: 0;
}

@media screen and (max-width: 440px) {
    .popupDropdown {
        left: 5px !important;
    }

    :global {
        .ant-picker-date-panel {
            width: 260px;
            & > .ant-picker-body {
                padding-right: 5px;
                padding-left: 5px;
            }
        }

        .ant-picker-time-panel {
            width: 100px;
            & > .ant-picker-content {
                & > .ant-picker-time-panel-column {
                    width: 40px;
                    & > .ant-picker-time-panel-cell {
                        & > .ant-picker-time-panel-cell-inner {
                            padding: 0;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.radio {
    margin: var(--margin-sm);
}
