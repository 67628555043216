.search {
    min-width: 300px;
}

:global {
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none !important;
    }
}

.searchMobile {
    width: 100%;
    border-color: var(--color-grey7);
    margin-bottom: 16px;
}

.searchIcon {
    font-size: 20px;
    margin-top: -4px;
}

.optionLabel {
    margin-right: var(--margin-sm);
}
