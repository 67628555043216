.title {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
}

.mobile {
  font-size: 20px;
}

.subtitle {
  color: var(--color-grey5);
  font-size: var(--font-size);
}
