.layout {
    min-height: calc(100vh - 120px);

    &Light {
        background: white;
    }

    :global {
        .ant-layout-content {
            background-color: var(--color-grey1);
        }
    }

    .bannerNotificationContainer {
        padding: 24px;
    }
}
