.text {
  margin-right: 0;
  padding: 0 5px;
}

.green {
  color: var(--color-green2);
}

.dark {
  color: var(--color-text);
}
